function BasicGridView(element, calendar, viewName) {
	var t = this;

	// exports
	t.renderBasic = renderBasic;
	t.setHeight = setHeight;
	t.setWidth = setWidth;
	t.renderDayOverlay = renderDayOverlay;
	t.defaultSelectionEnd = defaultSelectionEnd;
	t.renderSelection = renderSelection;
	t.clearSelection = clearSelection;
	t.reportDayClick = reportDayClick; // for selection (kinda hacky)
	t.dragStart = dragStart;
	t.dragStop = dragStop;
	t.getHoverListener = function () {
		return hoverListener;
	};
	t.colLeft = colLeft;
	t.colRight = colRight;
	t.colContentLeft = colContentLeft;
	t.colContentRight = colContentRight;
	t.getIsCellAllDay = function () {
		return true;
	};
	t.allDayRow = allDayRow;
	t.getRowCnt = function () {
		return rowCnt;
	};
	t.getColCnt = function () {
		return colCnt;
	};
	t.getColWidth = function () {
		return colWidth;
	};
	t.getClustering = function () {
		return clustering;
	};
	t.getDaySegmentContainer = function () {
		return daySegmentContainer;
	};

	t.getResources = calendar.fetchResources;

	// imports
	View.call(t, element, calendar, viewName);
	OverlayManager.call(t);
	SelectionManager.call(t);
	BasicGridEventRenderer.call(t);
	var opt = t.opt;
	var trigger = t.trigger;
	var renderOverlay = t.renderOverlay;
	var clearOverlays = t.clearOverlays;
	var daySelectionMousedown = t.daySelectionMousedown;
	var cellToDate = t.cellToDate;
	var dateToCell = t.dateToCell;
	var createExternalDragDayDates = t.createExternalDragDayDates;
	var resourceGridRangeToSegments = t.resourceGridRangeToSegments;
	var formatDate = calendar.formatDate;
	var calculateWeekNumber = calendar.calculateWeekNumber;

	// locals

	var table;
	var head;
	var headCells;
	var body;
	var bodyRows;
	var bodyCells;
	var bodyFirstCells;
	var bodyPosition;
	var firstRowCellInners;
	var firstRowCellContentInners;
	var daySegmentContainer;

	var viewWidth;
	var viewHeight;
	var colWidth;
	var weekNumberWidth;

	var rowCnt, colCnt, clustering;
	var showNumbers;
	var coordinateGrid;
	var hoverListener;
	var colPositions;
	var colContentPositions;

	var tm;
	var colFormat;
	var showWeekNumbers;

	//Customized for seedcode
	var headerDayClass;
	var breakoutHeader = opt('breakoutHeader');
	var breakoutHead;
	var breakoutHeadCells;
	var cloumnHighlights;
	var weekHeaderFormat;

	var manageClone = t.manageClone;

	//Customized for seedcode
	var resourceGrid;
	var showRowLabels;
	var rowLabelWidth;
	var resources;

	//Customized for seedcode - Add class to specify scroll area of calendar
	element.parent().addClass('calendar-scroll touch-scroll');

	/* Rendering
	------------------------------------------------------------*/

	element.addClass('fc-grid');
	//disableTextSelection(element.addClass('fc-grid'));

	function renderBasic(_rowCnt, _colCnt, _showNumbers, _clustering) {
		//Show horizontal row labels for resources - Customized for seedcode
		resources = t.getResources(false);
		showRowLabels = true;
		resourceGrid = true;

		rowCnt = _rowCnt;
		colCnt = _clustering ? _clustering.columnCount : _colCnt;
		showNumbers = _showNumbers;
		clustering = _clustering;

		updateOptions();

		cloumnHighlights = [];

		if (!body) {
			buildEventContainer();
		}

		buildTable();

		//Enable tooltips for this view //Customized for SeedCode
		calendar.generateBreakoutTooltips(
			'right',
			$(element),
			resources,
			false
		);
	}

	function updateOptions() {
		tm = opt('theme') ? 'ui' : 'fc';

		if (clustering) {
			colFormat = getFormatFromMatrix(
				opt('columnFormat'),
				(calendar.getContentWidth() - 100) / colCnt,
				true
			);
		} else {
			colFormat = getFormatFromMatrix(
				opt('columnFormat'),
				(calendar.getContentWidth() - 100) / colCnt
			);
		}

		showWeekNumbers =
			(opt('weekNumbers') && t.name !== 'basicWeek') ||
			(opt('weekNumbers') &&
				opt('titleFormat').toLowerCase() !== 'w' &&
				t.name === 'basicWeek');
	}

	function buildEventContainer() {
		daySegmentContainer = $(
			"<div class='fc-event-container' style='position:absolute;z-index:8;top:0;left:0'/>"
		).appendTo(element);
	}

	function buildTable() {
		var html = buildTableHTML();

		if (table) {
			table.remove();
		}
		table = $(html).appendTo(element);

		head = table.find('thead.fc-fixed-head');
		breakoutHead = $('.fc-breakout-header');
		headCells = head.find('.fc-day-header');
		breakoutHeadCells = breakoutHead.find('.fc-day-header');
		body = table.find('tbody');
		bodyRows = body.find('tr');
		bodyCells = body.find('.fc-day');
		dayNumbers = $('.fc-day').find('.fc-day-number'); //Customized for seedcode
		bodyFirstCells = bodyRows.find('td:first-child');

		firstRowCellInners = bodyRows.eq(0).find('.fc-day > div');
		firstRowCellContentInners = bodyRows
			.eq(0)
			.find('.fc-day-content > div');

		markFirstLast(head.add(head.find('tr'))); // marks first+last tr/th's
		markFirstLast(bodyRows); // marks first+last td's
		bodyRows.eq(0).addClass('fc-first');
		bodyRows.filter(':last').addClass('fc-last');

		bodyCells.each(function (i, _cell) {
			var date = cellToDate(Math.floor(i / colCnt), i % colCnt);
			trigger('dayRender', t, date, $(_cell));
		});
		dayBind(bodyCells);

		//Bind day numbers - Customized for seedcode

		dayNumbers.each(function (i, dayNumber) {
			var date = cellToDate(Math.floor(i / colCnt), i % colCnt);
			$(dayNumber).data({date: date});
		});
		dayNumberBind(dayNumbers);

		var content = breakoutHead;
		$('.fc-header').before(content);
		// content.css('display', 'block');
		$('.fc-content').css(
			'top',
			breakoutHead.outerHeight() - head.outerHeight() + 'px'
		);
	}

	/* HTML Building
	-----------------------------------------------------------*/

	function buildTableHTML() {
		//Customized for seedcode to split the header and content. This way we can make the content scrollable independent from the head
		var additionalHead = '';
		if (breakoutHeader) {
			additionalHead =
				"<table class='fc-border-separate fc-breakout-header dbk_columnHeader' style='width: 100%;' cellspacing='0'>" +
				buildBreakoutHeadHTML(true, false) +
				'</table>';
		}

		var html =
			additionalHead +
			"<table class='fc-border-separate' style='width:100%' cellspacing='0'>" +
			buildHeadHTML(false, breakoutHeader) +
			buildBodyHTML() +
			'</table>';

		return html;
	}

	function buildHeadHTML(isBreakout, hideLabels) {
		var headerClass = tm + '-widget-header';
		var html = '';
		var col;
		var date;
		var today = calendar.getNow().stripTime();
		var designationClass = isBreakout ? 'breakout' : 'fixed-head'; //Customized for seedcode
		var hideStyle = hideLabels ? 'display: none;' : ''; //Customized for seedcode
		// var borderStyle = hideLabels ? 'border:none;' : ''; //Customized for seedcode
		var borderStyle = hideLabels ? '' : ''; //Customized for seedcode
		var todayClass;

		html +=
			"<thead class='fc-" + designationClass + " dbk_columnHeader'><tr>"; //Customized for seedcode

		//customized for seedcode add vertical resource header
		html +=
			"<th class='fc-row-label fc-row-label-header " +
			headerClass +
			"' style='" +
			borderStyle +
			"'>" +
			"<span style='" +
			hideStyle +
			"'>" +
			htmlEscape(opt('resourceText')) +
			'</span>' +
			'</th>';
		for (col = 0; col < colCnt; col++) {
			if (clustering) {
				date = cellToDate(0, 0).add(
					col * clustering.typeOffset,
					clustering.type
				);
			} else {
				date = cellToDate(0, col);
			}
			todayClass = '';
			if (date.isSame(today, 'day')) {
				todayClass = 'fc-today';
			}
			html +=
				"<th class='fc-day-header " +
				todayClass +
				' fc-' +
				dayIDs[date.day()] +
				' ' +
				'dbk_day_' +
				dayIDs[date.day()] +
				' ' +
				headerClass +
				"' style='" +
				borderStyle +
				"'>" +
				"<span style='" +
				hideStyle +
				"'>" +
				htmlEscape(formatDate(date, colFormat)) +
				'</span>' +
				'</th>';
		}

		html += '</tr></thead>';

		return html;
	}

	function buildBreakoutHeadHTML(isBreakout, hideLabels) {
		if (clustering) {
			return buildWeekHeadHTML(isBreakout, hideLabels);
		} else {
			return buildDayHeadHTML(isBreakout, hideLabels);
		}
	}

	function buildDayHeadHTML(isBreakout, hideLabels) {
		var headerClass = tm + '-widget-header';
		var html = '';
		var col;
		var date;
		var today = calendar.getNow().stripTime();
		var weekends = opt('weekends');
		var colCntOffset = !weekends ? (colCnt / 7) * 2 : 0;
		var firstDay = Number(opt('firstDay'));
		var weekText = opt('weekText');
		var weekNumberTitle = opt('weekNumberTitle');
		var weekOfText = opt('weekOfText');
		var dynamicHead = true;
		var colDisplayFormat = colFormat;
		var weekHeaderString;
		var weekHeaderDisplay;
		var designationClass = isBreakout ? 'breakout' : 'fixed-head'; //Customized for seedcode
		var hideStyle = hideLabels ? 'display: none;' : ''; //Customized for seedcode
		var borderStyle = hideLabels ? 'border: none;' : ''; //Customized for seedcode
		var dynamicDayHeadStyle = !dynamicHead ? 'display: none' : '';
		var dynamicWeekHeadStyle;
		var additionalColClass = '';
		var weekColWidth =
			calendar.getContentWidth() / ((colCnt + colCntOffset) / 7);
		var todayClass;

		if (dynamicHead) {
			designationClass += ' dynamic-head';
			colDisplayFormat = 'M/D';
			//Correct first day if it is hidden
			if ((firstDay === 6 || firstDay === 0) && !weekends) {
				firstDay = 1;
			}

			//Assign our default string - This can be changed further down when necessary
			weekHeaderString = showWeekNumbers ? weekText : weekOfText;

			//Determine week header format
			if (colCnt + colCntOffset < 63 && weekColWidth > 275) {
				weekHeaderFormat = 'MMMM Do YYYY';
			} else if (colCnt + colCntOffset < 32 && weekColWidth > 200) {
				weekHeaderFormat = 'MMMM Do';
			} else if (colCnt + colCntOffset < 49 && weekColWidth > 175) {
				weekHeaderFormat = 'MMM Do';
			} else if (colCnt + colCntOffset < 63 && weekColWidth > 150) {
				weekHeaderFormat = 'M/D';
			} else if (colCnt + colCntOffset < 63) {
				weekHeaderFormat = 'MMMM';
				weekHeaderString = '';
			} else if (colCnt + colCntOffset > 62) {
				weekHeaderFormat = 'MMMM';
				weekHeaderString = '';
			}
		}

		html +=
			"<thead class='fc-" + designationClass + " dbk_columnHeader'><tr>"; //Customized for seedcode

		html +=
			"<th class='fc-row-label fc-row-label-header " +
			headerClass +
			"' style='" +
			borderStyle +
			"'>" +
			"<span style='" +
			hideStyle +
			"'>" +
			htmlEscape(opt('resourceText')) +
			'</span>' +
			'</th>';

		for (col = 0; col < colCnt; col++) {
			if (clustering) {
				date = cellToDate(0, 0).add(
					col * clustering.typeOffset,
					clustering.type
				);
			} else {
				date = cellToDate(0, col);
			}

			todayClass = '';
			if (date.isSame(today, 'day')) {
				todayClass = 'fc-today';
			}
			//We are showing dates in months
			if (weekHeaderFormat === 'MMMM') {
				dynamicWeekHeadStyle =
					!dynamicHead || 1 != date.format('D')
						? 'display: none'
						: '';
				weekHeaderDisplay = htmlEscape(
					formatLocalizedDate(date, weekHeaderFormat)
				);
			}
			//We are showing dates in weeks
			else {
				dynamicWeekHeadStyle =
					!dynamicHead || firstDay !== Number(date.format('d'))
						? 'display: none'
						: '';
				weekHeaderDisplay = showWeekNumbers
					? weekHeaderString +
						' ' +
						htmlEscape(calculateWeekNumber(date, 7))
					: weekHeaderString +
						' ' +
						htmlEscape(formatLocalizedDate(date, weekHeaderFormat));
			}

			additionalColClass =
				date.day() === firstDay ? ' fc-start-week' : '';

			html +=
				"<th data-date='" +
				date.format('YYYY-MM-DD') +
				"' class='fc-day-header " +
				todayClass +
				' fc-' +
				dayIDs[date.day()] +
				' ' +
				'dbk_day_' +
				dayIDs[date.day()] +
				' ' +
				headerClass +
				additionalColClass +
				"' style='" +
				borderStyle +
				"'>" +
				"<span class='day-header-week' style='" +
				dynamicWeekHeadStyle +
				"'>" +
				weekHeaderDisplay +
				'</span>' +
				"<span class='day-header-date' style='" +
				hideStyle +
				"'>" +
				(weekHeaderFormat === 'MMMM' && showWeekNumbers
					? weekNumberTitle + htmlEscape(calculateWeekNumber(date, 7))
					: htmlEscape(formatLocalizedDate(date, colDisplayFormat))) +
				'</span>' +
				"<span class='day-header-day' style='" +
				dynamicDayHeadStyle +
				"'>" +
				htmlEscape(formatDate(date, 'ddd')).charAt(0) +
				'</span>' +
				'</th>';
		}

		html += '</tr></thead>';

		return html;
	}

	function buildWeekHeadHTML(isBreakout, hideLabels) {
		var headerClass = tm + '-widget-header';
		var html = '';
		var col;
		var date;
		var today = calendar.getNow().stripTime();
		var weekText = opt('weekText');
		var weekOfText = opt('weekOfText');
		var weekNumberTitle = opt('weekNumberTitle');
		var quarterText = opt('quarterText');
		var fiscalYearStarts = opt('fiscalYearStarts');
		var colWidth = calendar.getContentWidth() / colCnt;

		var designationClass = isBreakout ? 'breakout' : 'fixed-head'; //Customized for seedcode
		var hideStyle = hideLabels ? 'display: none;' : ''; //Customized for seedcode
		var borderStyle = hideLabels ? 'border: none;' : ''; //Customized for seedcode
		var additionalColClass = '';
		var todayClass = '';

		var topHeaderClass = '';
		var topHeaderStyle = '';
		var topHeaderDisplay = '';
		var middleHeaderStyle = '';
		var middleHeaderDisplay = '';
		var bottomHeaderClass = '';
		var bottomHeaderStyle = '';
		var bottomHeaderDisplay = '';
		var month;
		var quarter;
		var year;
		var yearChange;
		var lookBackDate;

		var monthWeekCounter;

		designationClass += ' dynamic-head';

		html +=
			"<thead class='fc-" + designationClass + " dbk_columnHeader'><tr>"; //Customized for seedcode

		html +=
			"<th class='fc-row-label fc-row-label-header " +
			headerClass +
			"' style='" +
			borderStyle +
			"'>" +
			"<span style='" +
			hideStyle +
			"'>" +
			htmlEscape(opt('resourceText')) +
			'</span>' +
			'</th>';

		for (col = 0; col < colCnt; col++) {
			date = t.start
				.clone()
				.add(col * clustering.typeOffset, clustering.type);

			dateDay = date.date();

			if (month === undefined) {
				if (date.clone().subtract(7, 'days').month() !== date.month()) {
					middleHeaderStyle = '';
					monthWeekCounter = 0;
				} else {
					// Adjust for days before the month if not starting on a month
					if (
						date.clone().subtract(2, 'weeks').month() !==
						date.month()
					) {
						monthWeekCounter = 1;
					} else if (
						date.clone().subtract(3, 'weeks').month() !==
						date.month()
					) {
						monthWeekCounter = 2;
					}

					middleHeaderStyle = 'display: none;';
				}
			} else {
				if (date.month() !== month) {
					middleHeaderStyle = '';
					monthWeekCounter = 0;
				} else {
					middleHeaderStyle = 'display: none;';
				}
			}

			monthWeekCounter++;

			if (monthWeekCounter === 1 || monthWeekCounter === 3) {
				bottomHeaderClass = 'primary-header';
			} else {
				bottomHeaderClass = 'optional-header';
			}

			middleHeaderDisplay =
				colCnt >= 50 || (colWidth < 16 && colCnt >= 24)
					? formatLocalizedDate(date, 'MMM')
					: formatLocalizedDate(date, 'MMMM');

			// middleHeaderDisplay += date.quarter() !== quarter ? ' <div class="day-header-date-sub">' + htmlEscape(quarterText) + date.quarter() + '</div>' : '';

			yearChange = year !== date.year();
			month = date.month();
			year = date.year();

			if (!middleHeaderStyle) {
				cloumnHighlights.push(true);
			} else {
				cloumnHighlights.push(false);
			}

			topHeaderDisplay = '';
			topHeaderStyle = 'display: none;';

			if (
				date.fquarter(fiscalYearStarts).quarter !== quarter ||
				yearChange
			) {
				lookBackDate = date.clone().subtract(1, 'week');
				quarter = date.fquarter(fiscalYearStarts).quarter;
				if (
					lookBackDate.fquarter(fiscalYearStarts).quarter !==
						quarter ||
					lookBackDate.year() !== year
				) {
					if (month === 0 && !middleHeaderStyle) {
						topHeaderStyle = '';
						topHeaderClass = '';
						topHeaderDisplay = year;
					} else {
						topHeaderClass = 'day-header-date-sub-tick';
						topHeaderStyle = '';
					}
					topHeaderDisplay +=
						date
							.clone()
							.subtract(1, 'week')
							.fquarter(fiscalYearStarts).quarter !== quarter
							? ' <div class="day-header-date-sub">' +
								htmlEscape(quarterText) +
								date.fquarter(fiscalYearStarts).quarter +
								'</div>'
							: '';
				}
			}

			if (showWeekNumbers) {
				//Calculate whether or not to show week number title
				if (colWidth < 8 + 8 * (weekNumberTitle.length + 1)) {
					bottomHeaderDisplay = htmlEscape(
						calculateWeekNumber(date, 7)
					);
				} else {
					bottomHeaderDisplay =
						weekNumberTitle +
						htmlEscape(calculateWeekNumber(date, 7));
				}
			} else {
				bottomHeaderDisplay = htmlEscape(formatDate(date, 'DD'));
			}

			html +=
				"<th data-date='" +
				date.format('YYYY-MM-DD') +
				"' class='fc-day-header long-timescale-header " +
				todayClass +
				' fc-' +
				dayIDs[date.day()] +
				' ' +
				'dbk_day_' +
				dayIDs[date.day()] +
				' ' +
				headerClass +
				additionalColClass +
				"' style='" +
				borderStyle +
				"'>" +
				"<span class='day-header-week " +
				topHeaderClass +
				"' style='" +
				topHeaderStyle +
				"'>" +
				topHeaderDisplay +
				'</span>' +
				"<span class='day-header-date' style='" +
				hideStyle +
				' ' +
				middleHeaderStyle +
				"'>" +
				middleHeaderDisplay +
				'</span>' +
				"<span class='day-header-day " +
				bottomHeaderClass +
				"' style='" +
				bottomHeaderStyle +
				"'>" +
				bottomHeaderDisplay +
				'</span>' +
				'</th>';
		}

		html += '</tr></thead>';

		return html;
	}

	function buildBodyHTML() {
		var contentClass = tm + '-widget-content dbk_cellContent';
		var html = '';
		var row;
		var col;
		var date;
		var weekNumberContent;
		var weekNumberBorderStyle;
		var resource;
		var resourceClass;

		html += "<tbody class='dbk_calendarContent'>";

		for (row = 0; row < rowCnt; row++) {
			resource = resources[row];

			html += "<tr class='fc-week'>";

			//Customized for seedcode to add resources
			resourceClass = resource.class ? ' ' + resource.class : '';

			html +=
				"<td class='fc-row-label fc-row-label-content " +
				contentClass +
				resourceClass +
				"'>" +
				"<div class='row-head-content'>" +
				'<div>' +
				"<div class='name'>" +
				"<span data-toggle='tooltip' data-position='" +
				row +
				"'>" +
				resource.name +
				'</span>' +
				'</div>' +
				(resource.description
					? "<div class='description'>" +
						resource.description +
						'</div>'
					: '') +
				(resource.dynamicContent
					? "<div class='dynamic-content'>" +
						resource.dynamicContent +
						'</div>'
					: '') +
				'</div>' +
				'</div>' +
				'</td>';
			for (col = 0; col < colCnt; col++) {
				if (clustering) {
					date = date = t.start
						.clone()
						.add(col * clustering.typeOffset, clustering.type);
				} else {
					date = cellToDate(0, col);
				}

				html += buildCellHTML(date, resource, row);
			}

			html += '</tr>';
		}

		html += '</tbody>';

		return html;
	}

	function buildCellHTML(date, resource, breakoutPosition) {
		// date assumed to have stripped time
		var month = t.intervalStart.month();
		var today = calendar.getNow().stripTime();
		var cell = dateToCell(date); //Customized for seedcode so the day number can reference a specific cell. Used to insert more events label.
		var html = '';
		var contentClass = tm + '-widget-content dbk_cellContent';
		var classNames = [
			'fc-day',
			'fc-' + dayIDs[date.day()],
			'dbk_day_' + dayIDs[date.day()],
			contentClass,
		];

		if (date.month() != month) {
			classNames.push('fc-other-month');
		}
		if (date.isSame(today, 'day')) {
			classNames.push(
				'fc-today',
				tm + '-state-highlight',
				'dbk_day_today'
			);
		} else if (date < today) {
			classNames.push('fc-past');
		} else {
			classNames.push('fc-future');
		}

		html +=
			'<td' +
			" class='" +
			classNames.join(' ') +
			"'" +
			" data-date='" +
			date.format() +
			"'" +
			" data-resource='" +
			resource.nameSafe +
			"'" +
			" data-breakout-position='" +
			breakoutPosition +
			"'" +
			'>' +
			'<div>';

		//Customized for seedcode for show more and added class to target clicks for show day on click
		if (showNumbers) {
			html +=
				"<div class='dbk_dayNumberHeader'><div id='fc-cell-" +
				cell.row +
				'-' +
				cell.col +
				"' class='fc-day-number'>" +
				"<span class='more-events'></span>" +
				date.date() +
				'</div></div>'; //Customized for seedcode to contain cell reference
		}

		html +=
			"<div class='fc-day-content'>" +
			"<div style='position:relative'>&nbsp;</div>" +
			'</div>' +
			'</div>' +
			'</td>';
		return html;
	}

	/* Dimensions
	-----------------------------------------------------------*/

	function setHeight(height) {
		viewHeight = height;
		var headHeight = breakoutHeader
			? breakoutHead.outerHeight()
			: head.height(); //Customized for seedcode
		var bodyHeight = Math.max(viewHeight - headHeight, 0); //Customized for seedcode
		var rowHeight;
		var rowHeightLast;
		var cell;
		var rows = resources.length;

		if (opt('weekMode') == 'variable') {
			rowHeight = rowHeightLast = Math.floor(
				bodyHeight / (rows == 1 ? 2 : 6)
			);
		} else {
			rowHeight = Math.floor(bodyHeight / rows);
			rowHeightLast = bodyHeight - rowHeight * (rows - 1);
		}

		bodyFirstCells.each(function (i, _cell) {
			if (i < rows) {
				cell = $(_cell);
				cell.find('> div').css(
					'min-height',
					(i == rows - 1 ? rowHeightLast : rowHeight) - vsides(cell)
				);
			}
		});
	}

	function setWidth(width) {
		viewWidth = width;
		colPositions.clear();
		colContentPositions.clear();
		var firstDay = Number(opt('firstDay'));
		var breakPoint = 50;
		var colModulus;

		var headerColWidthModifier = 1;
		var newHeaderDayClass;

		weekNumberWidth = 0;
		rowLabelWidth = 0;

		if (showRowLabels) {
			rowLabelWidth = head.find('th.fc-row-label').outerWidth();
		}

		colWidth = Math.floor(
			(viewWidth - weekNumberWidth - rowLabelWidth) / colCnt
		);
		setOuterWidth(headCells.slice(0, -1), colWidth);
		setOuterWidth(breakoutHeadCells.slice(0, -1), colWidth);

		//Customized for SeedCode. Apply column width to label in last column so a long label doesn't push content. Remove 2 at the end for a bit of breathing room.
		var lastColWidth =
			viewWidth -
			weekNumberWidth -
			rowLabelWidth -
			colWidth * (colCnt - 1) -
			2;
		setOuterWidth(headCells.slice(-1).find('span'), lastColWidth);
		setOuterWidth(breakoutHeadCells.slice(-1).find('span'), lastColWidth);

		colModulus =
			weekHeaderFormat && weekHeaderFormat === 'MMMM' && showWeekNumbers
				? opt('weekends')
					? 7
					: 5
				: Math.ceil(breakPoint / colWidth);
		var adjustedColWidth = colWidth * colModulus;
		var adjustedLastColWidth =
			viewWidth -
			weekNumberWidth -
			rowLabelWidth -
			adjustedColWidth * (colCnt - 1) -
			2;

		var lastViewedCol;
		var visibleColumnHeaders = [];

		//Format breakout head ticks depending on if it is the first day of week or matches the modulus
		breakoutHeadCells.each(function (i, element) {
			$(element).css({left: colWidth * i + rowLabelWidth + 'px'});
			if (
				(showWeekNumbers &&
					weekHeaderFormat === 'MMMM' &&
					moment($(element).attr('data-date')).day() !== firstDay) ||
				((!showWeekNumbers ||
					!weekHeaderFormat ||
					weekHeaderFormat !== 'MMMM') &&
					i % colModulus &&
					!clustering)
			) {
				$(element).addClass('tick');
				$(element).removeClass('tick-label');
			} else {
				$(element).addClass('tick-label');
				$(element).removeClass('tick');
				visibleColumnHeaders.push(breakoutHeadCells[i]);

				lastViewedCol = i;
			}
		});

		//Set header classes based on column widths
		if (clustering && clustering.grouping === 'month') {
			headerColWidthModifier = 2.8;
		} else if (clustering && clustering.grouping === 'century') {
			headerColWidthModifier = 3.4;
		} else if (clustering) {
			headerColWidthModifier = 2.25;
		}

		if (colWidth > 16 * headerColWidthModifier) {
			newHeaderDayClass = 'col-lg';
		} else if (colWidth > 12 * headerColWidthModifier) {
			newHeaderDayClass = 'col-md';
		} else if (colWidth > 8 * headerColWidthModifier) {
			newHeaderDayClass = 'col-sm';
		} else if (colWidth > 6 * headerColWidthModifier) {
			newHeaderDayClass = 'col-xs';
		} else if (colWidth <= 6 * headerColWidthModifier) {
			newHeaderDayClass = 'col-xxs';
		}

		if (breakoutHead.hasClass(headerDayClass)) {
			breakoutHead.removeClass(headerDayClass);
		}
		breakoutHead.addClass(newHeaderDayClass);
		headerDayClass = newHeaderDayClass;

		bodyPosition = body[0].getBoundingClientRect();
	}

	/* Day clicking and binding
	-----------------------------------------------------------*/

	//Customized for seedcode to allow clicking on day numbers
	function dayNumberBind(dayNumbers) {
		dayNumbers.click(dayNumberClick);
	}

	function dayNumberClick(ev) {
		if (!opt('selectable')) {
			// if selectable, SelectionManager will worry about dayClick
			var date = calendar.moment($(this).data('date'));
			trigger('dayNumberClick', this, date, ev);
		}
	}

	function dayBind(days) {
		days.click(dayClick).mousedown(daySelectionMousedown);
		days.dblclick(dayDblClick);
		days.addTouch(true);
	}

	function longTouch(ev) {
		if (!opt('selectable')) {
			// if selectable, SelectionManager will worry about dayClick
			dayDblClick(ev.originalEvent.touches[0]);
		}
	}

	function dayClick(ev) {
		if (!opt('selectable')) {
			// if selectable, SelectionManager will worry about dayClick
			var date = calendar.moment($(this).data('date'));
			trigger('dayClick', this, date, ev);
		}
	}

	//Customized for seedcode for double click
	function dayDblClick(ev) {
		if (!opt('selectable')) {
			// if selectable, SelectionManager will worry about dayClick
			var date = calendar.moment($(this).data('date'));
			var breakoutPosition = $(this).data('breakout-position');
			var resource = resources[breakoutPosition]
				? resources[breakoutPosition]
				: null;
			ev.resource = resource;
			trigger('dayDblClick', this, date, ev);
		}
	}

	/* Semi-transparent Overlay Helpers
	------------------------------------------------------*/
	// TODO: should be consolidated with AgendaView's methods

	function renderDayOverlay(
		overlayStart,
		overlayEnd,
		refreshCoordinateGrid,
		resource
	) {
		// overlayEnd is exclusive

		if (refreshCoordinateGrid) {
			coordinateGrid.build();
		}

		var segments = resourceGridRangeToSegments(
			overlayStart,
			overlayEnd,
			null,
			resource,
			false,
			true,
			clustering
		);

		for (var i = 0; i < segments.length; i++) {
			var segment = segments[i];
			dayBind(
				renderCellOverlay(
					segment.row,
					segment.leftCol,
					segment.row,
					segment.rightCol
				)
			);
		}
	}

	function renderCellOverlay(row0, col0, row1, col1) {
		// row1,col1 is inclusive
		var rect = coordinateGrid.rect(row0, col0, row1, col1, element);
		return renderOverlay(rect, element);
	}

	/* Selection
	-----------------------------------------------------------------------*/

	function defaultSelectionEnd(start) {
		return start.clone().stripTime().add(1, 'days');
	}

	function renderSelection(start, end) {
		// end is exclusive
		renderDayOverlay(start, end, true); // true = rebuild every time
	}

	function clearSelection() {
		clearOverlays();
	}

	function reportDayClick(date, ev) {
		var cell = dateToCell(date);
		var _element = bodyCells[cell.row * colCnt + cell.col];
		trigger('dayClick', _element, date, ev);
	}

	/* External Dragging
	-----------------------------------------------------------------------*/

	function dragStart(_dragElement, ev, ui) {
		hoverListener.start(function (cell) {
			const draggedEvent = ui?.helper?.data;

			clearOverlays();
			if (cell) {
				const dateCell = {row: 0, col: cell.col};
				const newStart = clustering
					? t.start
							.clone()
							.add(
								cell.col * clustering.typeOffset,
								clustering.type
							)
					: cellToDate(dateCell);
				const dates = createExternalDragDayDates(
					newStart,
					draggedEvent
				);
				const resource = t.getResources(false)[cell.row];
				renderDayOverlay(dates.start, dates.end, false, [
					resource.name,
				]);
			}
		}, ev);
	}

	function dragStop(_dragElement, ev, ui) {
		const cell = hoverListener.stop();
		const draggedEvent = ui?.helper?.data;
		const isClone = manageClone(ev, ui, draggedEvent);

		clearOverlays();

		if (cell) {
			const dateCell = {row: 0, col: cell.col};
			const resource = t.getResources(false)[cell.row];
			const newStart = clustering
				? t.start
						.clone()
						.add(cell.col * clustering.typeOffset, clustering.type)
				: cellToDate(dateCell);
			const dates = createExternalDragDayDates(newStart, draggedEvent);
			trigger(
				'drop',
				_dragElement,
				dates.start,
				dates.end,
				ev,
				ui,
				{
					value: resource,
					field: 'resource',
				},
				isClone
			);
		}
	}

	/* Utilities
	--------------------------------------------------------*/

	coordinateGrid = new CoordinateGrid(function (rows, cols) {
		var e, n, p;
		headCells.each(function (i, _e) {
			e = $(_e);
			n = e.offset().left;
			if (i) {
				p[1] = n;
			}
			p = [n];
			cols[i] = p;
		});
		p[1] = n + e.outerWidth();
		bodyRows.each(function (i, _e) {
			if (i < rowCnt) {
				e = $(_e);
				n = e.offset().top;
				if (i) {
					p[1] = n;
				}
				p = [n];
				rows[i] = p;
			}
		});
		p[1] = n + e.outerHeight();
	});

	hoverListener = new HoverListener(coordinateGrid);

	colPositions = new HorizontalPositionCache(function (col) {
		return {bodyPosition: bodyPosition, cell: firstRowCellInners.eq(col)};
	});

	colContentPositions = new HorizontalPositionCache(function (col) {
		return {
			bodyPosition: bodyPosition,
			cell: firstRowCellContentInners.eq(col),
		};
	});

	function colLeft(col) {
		return colPositions.left(col);
	}

	function colRight(col) {
		return colPositions.right(col);
	}

	function colContentLeft(col) {
		return colContentPositions.left(col);
	}

	function colContentRight(col) {
		return colContentPositions.right(col);
	}

	function allDayRow(i) {
		return bodyRows.eq(i);
	}
}
